



































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { debounce } from 'underscore'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'
import BAutocomplete from '@/components/validation/BAutocomplete.vue'
import DialogDelete from '@/components/DialogDelete.vue'
import Toolbar from '@/components/CRUD/Toolbar.vue'
import _ from 'underscore'

@Component({
  components: {
    'AppForm': ValidationObserver,
    'AppInput': BInput,
    'AppAuto': BAutocomplete,
    'AppDialogDelete': DialogDelete,
    'AppToolbar': Toolbar,
  }
})
export default class TrackingUser extends Vue {
  //#region COMPUTED
  get error() {
    return this.$store.getters.error
  }
  get name() {
    return this.editedItem ? this.editedItem.fullname : ''
  }

  get listRoles() {
    return this.$store.getters.sharedTrackingRoles
  }

  get dataItems() {
    return this.$store.getters['master/trackingUser/data']
  }
  get items() {
    return this.dataItems.data
  }
  get totalItems() {
    return this.dataItems.totalRecords
  }
  get options() {
    return this.$store.getters['master/trackingUser/options']
  }
  set options(newVal) {
    this.$store.dispatch('master/trackingUser/OnOptions', newVal)
  }
  get search() {
    return this.$store.getters['master/trackingUser/search']
  }
  set search(newVal) {
    this.$store.dispatch('master/trackingUser/OnSearch', newVal)
    this.debouncedGetAnswer()
  }

  get formTitle() {
    return this.editedItem.id ? 'Update Item' : 'New Item'
  }
  //#endregion
  //#region DATA
  $refs!: { observer: InstanceType<typeof ValidationObserver> }
  debouncedGetAnswer:any = null
  headers:Array<object> = [
    { text: 'ID', align: 'start', value: 'id' },
    { text: 'Username', value: 'username' },
    { text: 'Fullname', value: 'fullname' },
    { text: 'Act', align: 'center', value: 'actions', sortable: false },
  ]
  footers:object = { itemsPerPageOptions: [ 25, 50, 100 ] }
  dialog:boolean = false
  dialogDelete:boolean = false
  tab:any = null
  tabItems:any = [ 'Data', 'Akses Fitur', 'Akses Scan' ]
  editedItem:any = {
    id: '',
    email: '',
    fullname: '',
    phone: '',
    trackingUserRoles: { listTrackingRolesId: [], listTrackingDetailRolesId: [] },
    trackingUserPermission: {
      isScan2: false,
      isScan3: false,
      isScan4: false,
      isScan5: false,
      isScan6: false,
    },
  }
  //#endregion
  //#region WATCH
  @Watch('dialog')
  handler(val:boolean) {
    val || this.close()
  }
  @Watch('dialogDelete')
  dialogDeleteChanged(val:boolean) {
    val || this.close()
  }
  //#endregion
  //#region METHODS
  onSearch(value:string) {
    this.search = value
  }
  initialize() {
    if (this.options.page !== 1) this.options.page = 1
    else this.$store.dispatch('master/trackingUser/ReadData')
  }
  editItem(item:any, act:string) {
    if (!item.trackingUserRoles) { // set default if null
      item.trackingUserRoles = Object.assign({}, { listTrackingRolesId: [], listTrackingDetailRolesId: [] })
    }
    item.trackingUserRoles.listTrackingRolesId = this.splitByComma(item.trackingUserRoles.listTrackingRolesId)
    item.trackingUserRoles.listTrackingDetailRolesId = this.splitByComma(item.trackingUserRoles.listTrackingDetailRolesId)
    
    if (act === 'edit') {
      this.editedItem = item
      this.dialog = true
    } else if (act === 'copy') {
      this.editedItem = Object.assign({}, {
        id: '',
        email: item.email,
        fullname: item.fullname,
        phone: item.phone,
        trackingUserRoles: item.trackingUserRoles,
        trackingUserPermission: item.trackingUserPermission,
      })
      this.dialog = true
    } else if (act === 'delete') {
      this.editedItem = item
      this.dialogDelete = true
    }
  }
  confirmDeleteItem(val:boolean) {
    if (val) this.$store.dispatch('master/trackingUser/DeleteData', this.editedItem)
    this.dialogDelete = false
  }
  saveItem() {
    this.$refs.observer.validate().then(success => {
      if (!success) return
      
      this.editedItem.trackingUserRoles.listTrackingRolesId = this.joinByComma(this.editedItem.trackingUserRoles.listTrackingRolesId)
      this.editedItem.trackingUserRoles.listTrackingDetailRolesId = this.joinByComma(this.editedItem.trackingUserRoles.listTrackingDetailRolesId)

      if (this.editedItem.id) {
        this.$store.dispatch('master/trackingUser/UpdateData', this.editedItem)
      } else {
        this.$store.dispatch('master/trackingUser/CreateData', this.editedItem)
      }
      this.close()
    })
  }
  close() {
    this.dialog = false
    this.dialogDelete = false
    this.tab = null
    this.$nextTick(() => {
      const defaultItem = {
        id: '',
        email: '',
        fullname: '',
        phone: '',
        trackingUserRoles: { listTrackingRolesId: [], listTrackingDetailRolesId: [] },
        trackingUserPermission: {
          isScan2: false,
          isScan3: false,
          isScan4: false,
          isScan5: false,
          isScan6: false,
        },
      }
      this.editedItem = Object.assign({}, defaultItem)
      const resetForm = this.$refs.observer
      if (resetForm) resetForm.reset()
    })
  }
  groupItems(item:any) {
    return _.chain(item).groupBy(x => x.group).map((val, key) => key).value()
  }
  itemByGroup(group:string, item:any) {
    return item.filter((x:any) => x.group === group)
  }
  onHide(item:string) {
    const group:any = document.getElementById(`show-${item}`)
    group.classList.toggle('d-none')

    const iconClosed:any = document.getElementById(`icon-closed-${item}`)
    iconClosed.classList.toggle('d-none')

    const iconOpened:any = document.getElementById(`icon-opened-${item}`)
    iconOpened.classList.toggle('d-none')
  }
  splitByComma(value:any) {
    value = value ? value : []
    if (typeof value === 'string') value = value.split(',').map((val:any) => Number(val))
    return value
  }
  joinByComma(value:any) {
    return value ? value.sort((a:any, b:any) => a - b).join(',') : ''
  }
  //#endregion
  created() {
    this.$store.dispatch('ReadSharedTrackingRoles')
  }
  
  isParentActive(id:number) {
    if (typeof(this.editedItem.trackingUserRoles.listTrackingRolesId) == 'string') return
    let temp = this.editedItem.trackingUserRoles.listTrackingRolesId.find((x:any) => x == id)
    return temp ? false : true
  }
  //#region MOUNTED
  mounted() {
    this.debouncedGetAnswer = debounce(this.initialize, 500)
  }
  //#endregion
}
